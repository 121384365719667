import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Blog = () => (
  <Layout>
    <Seo title="Blog" />
    <section className="blog-page">
      <div className="content">
        <h1>Blog</h1>
      </div>
    </section>
    <section className="articles">
      <a href="https://medium.com/nerd-for-tech/modular-modals-in-react-2b9bde0c5d9c">
        <img src="https://miro.medium.com/max/2400/1*9QGs7VU-xNGlzdxG18VIyQ.jpeg" alt="A modular cubic building" />
        <article>
          <h3 className="primary-text">Modular Modals in React</h3>
          <p className="primary-text">Feb 20 2020</p>
          <div className="divider" />
          <h4>A guide on how to create multi-purpose, modular &amp; scalable React Modals</h4>
        </article>
      </a>
      <a href="https://roilivne14.medium.com/where-is-a-new-css-8cee1141a5f3">
        <img src="https://miro.medium.com/max/2400/1*dhUaNFsYJMVV2d9m6wd2Qw.jpeg" alt="Legs resting on desk in front of a computer" />
        <article>
          <h3 className="primary-text">:where() :is() — A New CSS</h3>
          <p className="primary-text">Feb 5 2020</p>
          <div className="divider" />
          <h4>Write a cleaner CSS with :where() and :is() pseudo-classes</h4>
        </article>
      </a>
    </section>
  </Layout>
)

export default Blog
